import {
    Button, Link, Modal, ModalOverlay, ModalContent,
    ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Checkbox, useDisclosure
} from '@chakra-ui/react';
import React from 'react';
import { useState } from 'react';

export const ModalPopUp = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [checked, setChecked] = useState(false)
    localStorage.setItem('checked', checked)

    const handleClick = () => {
        setChecked(!checked)
        localStorage.setItem('checked', checked)
    }

    const handleCheck = () => {
        onClose();
        window.open("https://k2.sakalgb.com/Runtime/Runtime/Form/LASG-Dashboard/", "_blank");
    }

    return (
        <>
            <Button onClick={
                //check if checked value is true in local storage
                localStorage.getItem('checked') === 'true' ? handleCheck : onOpen
            } color="white" variant="solid" background="#008ABD" _hover={{
                backgroundColor: '#1f6885',
                transform: 'translateY(-1px)',
                textDecoration: 'none'
            }} paddingX={12}
            >Register / Login</Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Confirm Agreement</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Checkbox onChange={handleClick} checked={checked} colorScheme="blue" size="lg"
                        > By selecting "Continue to Application" you are confirming that you have read and agree to Lagos EPPPS's <Link href='/' isExternal color="blue" textDecoration="underline">Terms and Conditions.</Link></Checkbox>
                    </ModalBody>

                    <ModalFooter>
                        <Button color="#1f6885" variant="outline" borderColor="#008ABD" mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button color="white" variant="outline" background="#008ABD" _hover={{
                            backgroundColor: '#1f6885',
                            transform: 'translateY(-1px)',
                            textDecoration: 'none'
                        }} paddingX={12} isExternal disabled={!checked}
                            onClick={
                                handleCheck
                            }
                        >Continue to Application</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}