import React, { useEffect, useRef } from 'react';
import logo from '../../../../assets/images/epp-logo.svg'
import logoWebp from '../../../../assets/images/epp-logo.webp'
import styles from './Header.module.css';
import { NavLink } from 'react-router-dom';
import { NavContainer } from '../../components/UI/Container/NavContainer';
import {
    Divider, HStack, List, ListItem, VStack, Box, ButtonGroup, Button, Drawer, useDisclosure,
    DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton,
    Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Link, MenuItem, Image
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { MenuLink } from '../../components/UI/MenuLink';
import { ModalPopUp } from '../../components/ModalPopUp/ModalPopUp';


const CustomAccordion = ({ title, links, children }) => {
    return (
        <AccordionItem>
            {({ isExpanded }) => (
                <>
                    <h2>
                        <AccordionButton _hover={{ color: '#008ABD' }} _active={{ color: '#008ABD' }} _focus={{ color: '#008ABD' }}>
                            <Box flex='1' textAlign='left'>
                                {title}
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    {links.map(link => (
                        <>
                            <Link activeClassName={styles.active} href={link.to} isExternal>
                                <AccordionPanel pb={0} _hover={{ color: '#008ABD' }} _active={{ color: '#008ABD' }} _focus={{ color: '#008ABD' }}>
                                    <ListItem key={link.title} fontSize="md" _hover={{
                                        color: '#008ABD',
                                    }} isTruncated>
                                        {link.title}
                                    </ListItem>
                                    <Divider width={'100%'} margin="2" />
                                </AccordionPanel>
                            </Link>
                        </>
                    ))}
                    {children}
                </>
            )}
        </AccordionItem>
    )
}

const MobileLink = ({ to, title }) => {
    return (
        <NavLink activeClassName={styles.active} to={to} >
            <AccordionPanel pb={0} _hover={{ color: '#008ABD' }} _active={{ color: '#008ABD' }} _focus={{ color: '#008ABD' }}>
                <ListItem key={title} fontSize="md" _hover={{
                    color: '#008ABD',
                }} isTruncated>
                    {title}
                </ListItem>
                <Divider width={'100%'} margin="2" />
            </AccordionPanel>
        </NavLink>
    )
}


const NLink = ({ to, title }) => {
    return (
        <NavLink to={to} style={{ width: '100%' }} >
            <MenuItem key={title} fontSize="xs" >
                {title}
            </MenuItem>
        </NavLink>
    )
}

const Header = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const btnRef = useRef();
    const { isOpen, onOpen, onClose } = useDisclosure()

    const minLinks = [
        { title: 'Ministry of Physical Planning and Urban Development', to: 'http://mppud.lagosstate.gov.ng/' },
        { title: 'Lagos State Government', to: 'https://lagosstate.gov.ng/' },
        { title: 'Citizens\' Gate', to: 'https://citizensgate.lagosstate.gov.ng/' },
    ];

    const deptLinks = []

    const agencyLinks = [
        { title: 'LASPPPA - Lagos State Physical Planning Permit Authority', to: 'https://laspppa.lagosstate.gov.ng/' },
        { title: 'LASBCA - Lagos State Building Control Agency', to: 'https://lasbca.lagosstate.gov.ng/' },
        { title: 'LASURA - Lagos State Urban Renewal Agency', to: 'https://lasura.lagosstate.gov.ng/' },
        { title: 'LASPEMA - Lagos State Planning and Environmental Monitoring Authority', to: 'https://laspema.lagosstate.gov.ng/' },
    ];

    const regLinks = [
        { title: 'Federal Republic of Nigeria Scheme of Service', to: 'https://epp.lagosstate.gov.ng/regulations/FEDERAL_REPUBLIC_OF_NIGERIA_SCHEME_OF_SERVICE.pdf' },
        { title: 'Building Regulations 2005', to: 'https://epp.lagosstate.gov.ng/regulations/BUILDING_REGULATIONS_2005.pdf' },
        { title: 'Lagos State Urban and Regional Planning and Development', to: 'https://epp.lagosstate.gov.ng/regulations/LSURPD_LAW_2010.pdf' },
        { title: 'National Building Code of Nigeria, 2006', to: 'https://epp.lagosstate.gov.ng/regulations/National_Building_Code_of_Nigeria_2006.pdf' },
        { title: 'Town Planner (Registration, etc.) CAP T7LFN 2004', to: 'https://epp.lagosstate.gov.ng/regulations/TOPREC_Decree.pdf' },
        { title: 'Town Planning (Constitution of NITP) March, 2004', to: 'https://epp.lagosstate.gov.ng/regulations/TOWN_PLANNING_CONSTITUTION_2004.pdf' },
        { title: 'Architect (Registration, etc.) ACT 2004', to: 'https://epp.lagosstate.gov.ng/regulations/ARCON_Decree_of_1990_Revision_of_2004.pdf' },
        { title: 'Engineer (Registration, etc.) ACT 2010', to: 'https://epp.lagosstate.gov.ng/regulations/COREN_ACT_2010.pdf' },
        { title: 'Lagos State Ministry of Physical Planning and Urban Development', to: 'http://mppud.lagosstate.gov.ng/' },
        { title: 'British Standard Code of Practice, 8110', to: 'http://cns-engineer.blogspot.com/2008/12/british-standard-code-for.html' },
        { title: 'Reforms', to: 'https://epp.lagosstate.gov.ng/standards/REFORMS_FOR_LASPPPA_2020.pdf' },
        { title: 'Statutory Clearance Table', to: 'https://epp.lagosstate.gov.ng/regulations/STATUTORY_CLEARANCE_TABLE.pdf' },
    ];

    return (
        <React.Fragment>
            <header className={styles.header}>
                <NavContainer>
                    <VStack spacing={4}
                        align='stretch' >
                        <Box >
                            <HStack spacing={4} align="center" justify="space-between">
                                <Box  >
                                    <Link href="/">
                                        <picture>
                                            <source srcSet={logoWebp} type="image/webp" />
                                            <source srcSet={logo} type="image/png" />
                                            <Image src={logo} alt="logo" width={{ base: '10rem', md: '15rem' }} height='auto' />
                                        </picture>
                                    </Link>
                                </Box>
                                <Box as='nav' flexDirection="row" justifyContent="flex-end" display={{ base: 'none', md: 'flex' }} width={'50%'} className={styles.navlinks}>
                                    <List as="ul" display="flex" flexDirection="row" justifyContent="space-between" width='100%' fontSize={{ base: 'sm', md: 'md' }}>
                                        <ListItem as="li">
                                            <NavLink to="/" activeClassName={styles.active}>Home</NavLink>
                                        </ListItem>
                                        <ListItem as="li">
                                            <NavLink to="/services" activeClassName={styles.active}>Services</NavLink>
                                        </ListItem>
                                        <MenuLink title="Regulations" links={regLinks.map(link => ({ ...link, to: `${link.to}` }))}>
                                            <NLink to='/regulations-and-laws' activeClassName={styles.active} title='Regulations and Laws' />
                                        </MenuLink>

                                        <ListItem as="li">
                                            <NavLink to="/faqs" activeClassName={styles.active}>FAQs</NavLink>
                                        </ListItem>
                                        <ListItem as="li">
                                            <NavLink to="/search-applications" activeClassName={styles.active}>Search Applications</NavLink>
                                        </ListItem>
                                    </List>
                                </Box>
                                <ButtonGroup flexDirection="row" justifyContent="flex-end" display={{ base: 'none', md: 'flex' }} width='25%' className={styles.btngrp}>
                                    <Button as={Link} href="https://k2.sakalgb.com/Runtime/Runtime/Form/LASG-Dashboard/" color="#1f6885" variant="outline" borderColor="#008ABD" _hover={{
                                        borderColor: '#1f6885',
                                        transform: 'translateY(-1px)',
                                        textDecoration: 'none'
                                    }} paddingX={12} isExternal
                                    >Staff Login</Button>
                                    <ModalPopUp />
                                </ButtonGroup>
                                <Button as='div' variant="ghost" variantColor="blue" display={{ base: 'flex', md: 'none' }}
                                    aria-label="menu" ref={btnRef} onClick={onOpen}>
                                    <HamburgerIcon />
                                </Button>
                            </HStack>
                        </Box>
                    </VStack>
                </NavContainer>
            </header>
            <Drawer
                isOpen={isOpen}
                placement='top'
                onClose={onClose}
                finalFocusRef={btnRef}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton top={5} right={5} />
                    <DrawerHeader>
                        <Box width={'40%'} >
                            <Link href="/" >
                                <picture>
                                    <source srcSet={logoWebp} type="image/webp" />
                                    <source srcSet={logo} type="image/png" />
                                    <Image src={logo} alt="logo" width={{ base: '10rem', md: '15rem' }} height='auto' />
                                </picture>
                            </Link>
                        </Box>
                    </DrawerHeader>

                    <DrawerBody>

                        <List as="ul" display="flex" flexDirection="column"  >

                            <Accordion allowToggle>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex='1' textAlign='left'>
                                                <ListItem as="li">
                                                    <NavLink to="/">Home</NavLink>
                                                </ListItem>
                                            </Box>
                                        </AccordionButton>
                                    </h2>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex='1' textAlign='left'>
                                                <ListItem as="li">
                                                    <NavLink to="/services">Services</NavLink>
                                                </ListItem>
                                            </Box>
                                        </AccordionButton>
                                    </h2>
                                </AccordionItem>
                                <CustomAccordion title="Ministry" links={minLinks.map(link => ({ ...link, to: `${link.to}` }))} />
                                <CustomAccordion title="Departments" links={deptLinks.map(link => ({ ...link, to: `${link.to}` }))} >
                                    <MobileLink to="/services/" title='PPD - Physical Planning Department' />
                                    <MobileLink to="/services/" title='DMD - Development Matters Department' />
                                    <MobileLink to="/services/" title='RMPD - Regional & Master Plan Department' />
                                    <MobileLink to="/services/" title='LASPIC - Lagos State Planning Information Centre' />
                                    <MobileLink to="/services/" title='TSD - Technical Services Department' />
                                    <MobileLink to="/services/" title='UDD - Urban Development Department' />
                                </CustomAccordion>
                                <CustomAccordion title="Agencies" links={agencyLinks.map(link => ({ ...link, to: `${link.to}` }))} />
                                <CustomAccordion title="Regulations" links={regLinks.map(link => ({ ...link, to: `${link.to}` }))}>
                                    <MobileLink to='/regulations-and-laws' activeClassName={styles.active} title='Regulations and Laws' />
                                </CustomAccordion>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex='1' textAlign='left'>
                                                <ListItem as="li">
                                                    <NavLink to="/faqs">FAQs</NavLink>
                                                </ListItem>
                                            </Box>
                                        </AccordionButton>
                                    </h2>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex='1' textAlign='left'>
                                                <ListItem as="li">
                                                    <NavLink to="/search-applications">Search Applications</NavLink>
                                                </ListItem>
                                            </Box>
                                        </AccordionButton>
                                    </h2>
                                </AccordionItem>
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex='1' textAlign='left'>
                                                <ListItem as="li">
                                                    <NavLink to="/contact-us">Contact us</NavLink>
                                                </ListItem>
                                            </Box>
                                        </AccordionButton>
                                    </h2>
                                </AccordionItem>
                            </Accordion>

                        </List>


                    </DrawerBody>

                    <DrawerFooter display="flex" flexDirection="column" gap={4}>

                        <Button as={Link} href="https://k2.sakalgb.com/Runtime/Runtime/Form/LASG-Dashboard/" color="#1f6885" variant="outline" borderColor="#008ABD" _hover={{
                            borderColor: '#1f6885',
                            transform: 'translateY(-1px)',
                            textDecoration: 'none'
                        }} paddingX={8} isExternal
                        >Staff Login</Button>
                        <ModalPopUp />

                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </React.Fragment >
    )
}

export default Header